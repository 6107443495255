import { QueryClient } from "@tanstack/react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      throwOnError: (error) => {
        return !error.isHttp;
      },
    },
    queries: {
      throwOnError: (error) => {
        return !error.isHttp;
      },
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      keepPreviousData: true,
    },
  },
});
